import { AxiosResponse } from "axios";

import { axiosInstanceV2 } from "api/global";
import { handleApiError } from "api/helpers";
import { setCookie } from "utils/cookie";
import { COOKIES, MonthInSeconds } from "constants/enum";

import {
    PostVisitorContact,
    PostVisitorContactResponse,
    WrappedShowUpPageResponse,
    WrappedBookingItemResponse,
    WrappedCustomerResponse,
    PostVerifyContact,
    PostVerifyContactResponse,
    PostRetryVerifyContact,
} from "./contacts.types";

const storeCustomerUuid = (customerUuid?: string) => {
    if (!customerUuid) {
        return;
    }

    setCookie(COOKIES.CUSTOMER_UUID, customerUuid, {
        path: "/",
        "max-age": MonthInSeconds,
    });
};

export const postContact = async (data: PostVisitorContact) =>
    await axiosInstanceV2
        .post("/attraction-service/contacts/mfa/v1", data)
        .then((response: AxiosResponse<PostVisitorContactResponse>) => {
            storeCustomerUuid(response.data.data?.user?.customerUuid);

            return response.data;
        })
        .catch(handleApiError);

export const updateContact = async (data: PostVisitorContact) =>
    await axiosInstanceV2
        .put("/attraction-service/contacts/v1", data)
        .then((response: AxiosResponse<PostVisitorContactResponse>) => {
            const { customerUuid } = response.data.data?.user;

            storeCustomerUuid(customerUuid);

            return customerUuid;
        })
        .catch(handleApiError);

export const verifyContact = async (data: PostVerifyContact) =>
    await axiosInstanceV2
        .post("/attraction-service/contacts/mfa/v1/verify", data)
        .then((response: AxiosResponse<PostVerifyContactResponse>) => {
            storeCustomerUuid(response.data.data?.user?.customerUuid);

            return {
                status: response.data.status,
                ...response.data.data,
            };
        })
        .catch(handleApiError);

export const retryVerifyContact = async (data: PostRetryVerifyContact) =>
    await axiosInstanceV2
        .post("/attraction-service/contacts/mfa/v1/retry", data)
        .then((response: AxiosResponse<PostVerifyContactResponse>) => {
            storeCustomerUuid(response.data.data?.user?.customerUuid);

            return {
                status: response.data.status,
                ...response.data.data,
            };
        })
        .catch(handleApiError);

export const getShowUpCustomerBookings = async (
    uuid: string
): Promise<WrappedShowUpPageResponse> =>
    await axiosInstanceV2
        .get(`/gws/web/v1/show-up/customers/${uuid}/bookings`)
        .then(
            (response: AxiosResponse<WrappedShowUpPageResponse>) =>
                response?.data
        )
        .catch(handleApiError);

export const putVisitIntentionStatus = async (
    customerId: string,
    bookingId: string
): Promise<WrappedBookingItemResponse> =>
    await axiosInstanceV2
        .put(
            `/gws/web/v1/show-up/customers/${customerId}/booking/${bookingId}/confirm`
        )
        .then(
            (response: AxiosResponse<WrappedBookingItemResponse>) =>
                response?.data
        )
        .catch(handleApiError);

export const getContactV1 = async (uuid: string) =>
    await axiosInstanceV2
        .get(`/attraction-service/contacts/v1/${uuid}`)
        .catch(handleApiError);

export const cancelAllAppointments = async (customerId: string) =>
    await axiosInstanceV2
        .delete(`/gws/web/v1/show-up/customers/${customerId}/bookings`)
        .catch(handleApiError);

export const getClinicIdByCustomerUuid = async (
    customerUuid: string
): Promise<WrappedCustomerResponse> =>
    await axiosInstanceV2
        .get(`/gws/web/v1/customers/${customerUuid}`)
        .then(
            (response: AxiosResponse<WrappedCustomerResponse>) => response?.data
        )
        .catch(handleApiError);
