import { AxiosResponse } from "axios";

import {
    CityType,
    GetClinicByClinicIdResponse,
    GetClinicByClinicIdType,
} from "api/location";
import { axiosInstance, axiosInstanceV2 } from "api/global";
import { handleApiError } from "api/helpers";
import { CountryVersion, CountrySlugs } from "constants/enum";

import { UserLocationCityResponse, CitiesResponse } from "./location.types";

const PROD_API_URL = "https://api-v2.smile2impress.com";

export const fetchUserLocationCity = async () =>
    await axiosInstance
        .get("/user-region-service/v1/region-lang")
        .then((response: AxiosResponse<UserLocationCityResponse>) => {
            return response.data.result.city;
        })
        .catch(handleApiError);

export const fetchCities = async (
    countryName: CountryVersion
): Promise<CityType[]> =>
    await axiosInstanceV2
        .get(
            `${PROD_API_URL}/attraction-service/locations/v2/country/${CountrySlugs[countryName]}/clinicsIds`
        )
        .then((response: AxiosResponse<CitiesResponse>) => {
            return response.data.data;
        })
        .catch(handleApiError);

export const fetchClinicById = async ({ clinicId }: GetClinicByClinicIdType) =>
    await axiosInstanceV2
        .get(
            `${PROD_API_URL}/attraction-service/locations/v1/clinics/${clinicId}`
        )
        .then((response: AxiosResponse<GetClinicByClinicIdResponse>) => {
            return response.data;
        })
        .catch(handleApiError);
