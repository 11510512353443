export enum FormTestID {
    ROOT = "container-form-id",
    FORM = "container-full-form-id",
    IS_ACCEPT = "is-accept-checkbox",
    CHECKBOX_REMINDER_AND_SPECIAL_OFFER_STATUS = "checkbox-reminder-and-special-offers-status",
    CHECKBOX_REMINDER_STATUS = "checkbox-reminder-status",
    CHECKBOX_SPECIAL_OFFER = "checkbox-special-offers",
    SUBMIT = "submit-button",
    CLINIC = "clinic",
    FRANCE_ACCEPT = "is-accept-france-checkbox",
    COUNTRY_CODE = "country-code-select",
}
