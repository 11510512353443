import { LeadType, PostVisitorContact, updateContact } from "api/contacts";
import { TEENS_ROUTES } from "configs/teens-routes";
import { redirectAnywayPromise } from "utils/redirectAnywayPromise";

export const updateLeadType = async ({
    email,
    phone,
    firstName,
    clinicId,
}: PostVisitorContact) => {
    const updateContactPromise = await updateContact({
        email,
        phone,
        firstName,
        clinicId,
        properties: {
            lead_type: LeadType.Teens,
        },
    });

    return Promise.race([redirectAnywayPromise, updateContactPromise]);
};

export const checkIsFromTeenPage = (path: string) =>
    Boolean(TEENS_ROUTES.find((route) => path.includes(route)));
