import { ErrorsResponseType, StatusesResponseType } from "api/constants";

export interface UserLocationCityResponse {
    result: {
        city: string;
    };
}

export enum ClinicType {
    Teens = "teens",
    Adults = "adults",
    Combined = "combined",
}

export interface ClinicGeolocation {
    googleMapClinicUrl?: string;
    googleMapParkingUrl?: string;
    latitude?: string;
    longitude?: string;
}

export interface ClinicInfo {
    id: number;
    address: string;
    cityId: number;
    clinicType: ClinicType;
    comment: null | string;
    createdAt: string;
    email: null | string;
    isExternal: boolean;
    phone: string | null;
    title: string;
    updatedAt: string;
    usesInhouseBookings: boolean;
    usesInternalMailEngine: boolean;
    geoData: ClinicGeolocation;
    isFlagship: boolean;
    url: null | string;
    postCode: number | null;
    openingTime: string;
    closingTime: string;
    scheduleExists: boolean | null;
}

export type ClinicWithCityTitleProps = ClinicInfo & {
    cityTitle: string;
};

export interface CityType {
    id: number;
    title: string;
    label: string | null;
    regionId: number;
    clinics: ClinicInfo[];
    clinicIds: number[];
}

export interface GetClinicByClinicIdType {
    clinicId: number;
}

export interface GetClinicByClinicIdResponse {
    data: ClinicDateType | null;
    status: StatusesResponseType;
    error: ErrorsResponseType;
}

export interface ClinicDateType {
    id: number;
    title: string;
    address: string;
    region: {
        title: string;
        timezone: string;
    };
    city: {
        id: number;
        title: string;
    };
    geoData: {
        googleMapClinicUrl: string | null;
        googleMapParkingUrl: string | null;
        latitude: string;
        longitude: string;
    };

    uses_internal_mail_engine?: boolean;
    country?: {
        title: string;
        code: string;
    };
}

export interface CitiesResponse {
    data: CityType[] | null;
    status: StatusesResponseType;
    error: ErrorsResponseType;
}
