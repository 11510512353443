import { CityType, ClinicInfo, ClinicType } from "api/location";
import { LeadType } from "api/contacts";
import {
    CountryVersion,
    HubspotCreationSource,
    HubspotProperties,
    Language,
    Theme,
} from "constants/enum";
import { getUtmQueryParamsFromCookies } from "services/UtmService";
import { getOpenPage, getSourcePage } from "services/VisitorTrajectoryService";
import { checkIsFromTeenPage } from "services/TeensService";
import { getGclid } from "services/GoogleAdsService";
import { getPreferredLanguage } from "utils/language";
import { OptionProps } from "components/Select";

export const clearPhone = (phone: string) => phone.replace(/[\s-–]/g, "");
export const clearEmail = (email: string) => email.toLowerCase();

export const getCityObj = (cities: CityType[], cityName: string) =>
    cities.find((cityItem) => cityItem.title === cityName);

export const getCityOption = (cities: OptionProps[], cityName: string) =>
    cities.find((cityItem) => cityItem.value === cityName);

export const convertCitiesToOptions = (cities: CityType[]) =>
    cities.map(({ id, title, label }) => ({
        id: id.toString(),
        value: title,
        label: label || title,
    }));

export const getClinicsByCity = (cities: CityType[], value: string) => {
    const city = cities.find(({ title }) => title === value);

    return city?.clinics || [];
};

export const getCheckboxTheme = (theme: Theme, version: CountryVersion) => {
    switch (version) {
        case CountryVersion.GERMANY:
            return Theme.GERMANY;
        default:
            return theme;
    }
};

export const sortClinicsByFlagships = (clinics: ClinicInfo[]) =>
    clinics.sort((a, b) => {
        return Number(b.isFlagship) - Number(a.isFlagship);
    });

export const prepareHubspotProperties = (
    language: Language,
    clinic: string,
    asPath: string,
    hasReminderAndMarketingStatus?: boolean,
    hasReminderStatus?: boolean,
    hasSpecialOffers?: boolean,
    instagramUser?: string,
    landingPageName?: string,
    isPageInExperiment?: boolean,
    experimentGroupId?: number
) => {
    const properties: { [key: string]: unknown } = {};
    const isTeens = clinic.split("-")[1] === ClinicType.Teens;
    const isFromTeensRoute = Boolean(asPath) && checkIsFromTeenPage(asPath);
    const utmQueryParams = getUtmQueryParamsFromCookies();
    const openPage = getOpenPage();
    const sourcePage = getSourcePage();
    const hubspotLanguage = getPreferredLanguage(language);
    const pathname = window?.location?.pathname;
    const gclid = getGclid();

    properties[HubspotProperties.OPEN_PAGE] = openPage;
    properties[HubspotProperties.SUBMIT_PAGE] = pathname;
    properties[HubspotProperties.SOURCE_PAGE] = sourcePage;
    properties[HubspotProperties.REMINDER_CONTACT_STATUS] =
        !!hasReminderAndMarketingStatus || !!hasReminderStatus;
    properties[HubspotProperties.MARKETING_CONTACT_STATUS] =
        !!hasReminderAndMarketingStatus || !!hasSpecialOffers;
    properties[HubspotProperties.GOOGLE_ADS_ID] = gclid;
    properties[HubspotProperties.CREATION_SOURCE] =
        HubspotCreationSource.WEBSITE;

    if (isFromTeensRoute || isTeens) {
        properties[HubspotProperties.LEAD_TYPE] = LeadType.Teens;
    }

    if (instagramUser) {
        properties[HubspotProperties.INSTAGRAM_USER] = instagramUser;
    }
    if (landingPageName) {
        properties[HubspotProperties.LANDING_PAGE_NAME] = landingPageName;
    }

    if (hubspotLanguage) {
        properties[HubspotProperties.LANGUAGE] = hubspotLanguage;
    }

    if (isPageInExperiment && experimentGroupId) {
        properties[HubspotProperties.EXPERIMENT_ID] = experimentGroupId;
    }
    return {
        ...properties,
        ...utmQueryParams,
    };
};
