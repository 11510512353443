import { forwardRef } from "react";
import NumberFormat from "react-number-format";
import {
    Box,
    Text,
    FormControl,
    FormLabel,
    Input as ChakraInput,
    Icon,
    MagnifyingGlassOutlineIcon,
    CrossFilledIcon,
    ChevronDownOutlineIcon,
} from "@smile2impress/components";

import { Theme } from "constants/enum";
import { ClinicPickerTestID } from "components/BookingWidget/components/ClinicPicker/tests/types";

import { InputProps } from "./Input.types";

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            id,
            name,
            placeholder,
            theme = Theme.DARK,
            errorText,
            value = "",
            type,
            disabled,
            onChange,
            onBlur,
            onFocus,
            onKeyDown,
            onClearValue,
            mask,
            tabIndex,
            pl,
            w,
            maxLength,
            isSearch,
            isHideChevron = false,
            isActiveMargin = true,
            dataTestId,
        },
        ref
    ) => {
        const maskedValue = mask
            ? parseInt(value?.toString()?.match(/\d/g)?.join("") || "", 10)
            : value;
        const currentValue = maskedValue === null ? "" : maskedValue || "";

        return (
            <Box
                position="relative"
                w="100%"
                {...(isActiveMargin && { mb: isSearch ? [0, 0, 4] : 4 })}
            >
                <FormControl variant="floating">
                    {isSearch && (
                        <>
                            <Icon
                                pointerEvents={"none"}
                                position="absolute"
                                left={0}
                                top={"50%"}
                                transform={"translateY(-50%)"}
                                as={MagnifyingGlassOutlineIcon}
                            />
                            {value && (
                                <Icon
                                    position="absolute"
                                    right={0}
                                    top={"50%"}
                                    transform={"translateY(-50%)"}
                                    as={CrossFilledIcon}
                                    onClick={onClearValue}
                                    color="text.tertiary"
                                    data-testid={
                                        ClinicPickerTestID.CLINIC_SELECT_INPUT_CLEAR_BUTTON
                                    }
                                />
                            )}
                            {!value && !isHideChevron && (
                                <Icon
                                    display={["none", "none", "block"]}
                                    pointerEvents={"none"}
                                    position="absolute"
                                    right={0}
                                    top={"50%"}
                                    transform={"translateY(-50%)"}
                                    as={ChevronDownOutlineIcon}
                                />
                            )}
                        </>
                    )}
                    <ChakraInput
                        {...(mask
                            ? {
                                  as: NumberFormat as any,
                                  displayType: "input",
                                  format: mask,
                                  getInputRef: ref,
                              }
                            : {
                                  ref,
                              })}
                        variant="flushed"
                        pl={isSearch ? "33px" : pl}
                        disabled={disabled}
                        maxLength={maxLength}
                        type={type}
                        name={name}
                        id={id}
                        value={currentValue}
                        placeholder={" "}
                        tabIndex={tabIndex}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        data-testid={dataTestId}
                        w={w || "100%"}
                        borderColor={
                            errorText ? "line.primary" : "line.secondary"
                        }
                        transition="border 0.2s linear"
                        _focus={{
                            borderColor: "text.link",
                        }}
                    />
                    <FormLabel
                        pl={isSearch ? 4 : 0}
                        color={
                            theme === Theme.DARK
                                ? value
                                    ? "text.secondary"
                                    : "text.primary"
                                : value
                                ? "text.secondaryInverse"
                                : "text.primaryInverse"
                        }
                    >
                        {placeholder}
                    </FormLabel>
                </FormControl>

                {errorText && (
                    <Text mt={0.5} color="text.accent" textStyle="caption">
                        {errorText}
                    </Text>
                )}
            </Box>
        );
    }
);
