import { COOKIES, Language } from "constants/enum";
import { getCookiesValueByName } from "utils/cookie";
import { languages } from "configs/languageConfig";

export const getLanguageFromURL = () => {
    const isWindowUndefined = typeof window === "undefined";
    const defaultLanguage = languages.es;

    if (isWindowUndefined) {
        return defaultLanguage;
    }

    const currentHref = window?.location?.pathname;
    const languageFromURL = currentHref?.substr(1, 2) as keyof typeof languages;
    const language = languages[languageFromURL] ?? defaultLanguage;

    return language;
};

export const getPreferredLanguage = (language: Language) =>
    language || getCookiesValueByName(COOKIES.PREFERRED_LANG);
