import {
    ConfirmationStatus,
    IUniversalResponseDTO,
    IShowUpPageResponseDTO,
    IBookingItemResponseDTO,
    ICustomerResponseDTO,
} from "@smile2impress/web-types";

import { ClinicDateType } from "api/location";
import { MFA_METHODS } from "constants/enum";

export interface PostVisitorContact {
    email: string;
    firstName: string;
    phone: string;
    clinicId: number;

    lastName?: string;
    city?: string;
    cityId?: number;
    properties?: any;
}

export interface PostVerifyContact {
    user: PostVisitorContact;
    token: string;
    mfaMethod: MFA_METHODS;
    destination: string;
    confirmationCode: string;
}

export interface PostRetryVerifyContact {
    token: string;
    mfaMethod: MFA_METHODS;
    destination: string;
}

export enum LeadType {
    Teens = "Teens",
    Adults = "Adults",
}

export interface ShowUpContactsResponse {
    data: {
        bookings: ShowUpBookingsResponse[];
        firstName: string;
    };
}

export interface ShowUpBookingsResponse {
    startsAt: string;
    endsAt: string;
    uuid: string;
    visitIntentionStatus: ConfirmationStatus;
    location: ClinicDateType;
}

export interface PostVisitorContactResponse {
    data: {
        user: { customerUuid?: string };
        mfaRequired: boolean;
        mfaMethods: MFA_METHODS;
        token: string;
        expire: string;
    };
}

export interface PostVerifyContactResponse {
    status: string;
    data: {
        user: PostVisitorContact & { customerUuid: string };
    };
}

export interface ShowUpPageBookingItem {
    uuid: string;
    startsAt: string;
    endsAt: string;
    visitIntentionStatus: ConfirmationStatus;
    /* Temporary copied type from web-types because of difference in backend response and docs: 
        cityTitle changed to cityName */
    clinic: {
        id: number;
        address: string;
        googleMapClinicUrl: string;
        latitude: string;
        longitude: string;
        title: string;
        timezone: string;
        cityLabel: string | null;

        cityTitle?: string;
        cityName?: string;
    };
}

export interface ShowUpPageResponse extends IShowUpPageResponseDTO {}

export interface WrappedShowUpPageResponse
    extends IUniversalResponseDTO<ShowUpPageResponse> {}

export interface BookingItemResponse extends IBookingItemResponseDTO {}

export interface WrappedBookingItemResponse
    extends IUniversalResponseDTO<BookingItemResponse> {}

export interface CustomerResponse extends ICustomerResponseDTO {}

export interface WrappedCustomerResponse
    extends IUniversalResponseDTO<CustomerResponse> {}
