import { sendAllSnapchatAnalitic } from "./sendSnapchatAnalitic";
import { sendAllFacebookAnalitic } from "./sendFacebookAnalitic";
import { AnaliticData, ExperimentData } from "./types";
import { sendAllGoogleAnalitic } from "./sendGoogleAnalitic";

export const sendAnalitic = async ({
    version,
    cityTitle,
    firstName,
    lastName,
    email,
    phone,
}: AnaliticData & ExperimentData) => {
    await sendAllFacebookAnalitic({
        version,
        cityTitle,
        firstName,
        lastName,
        email,
        phone,
    });

    sendAllSnapchatAnalitic({
        email,
        phone,
    });

    sendAllGoogleAnalitic();
};
