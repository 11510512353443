import { GTMEvents } from "./events";

export const sendGTMEvent = (
    eventName: string,
    payload?: { [key: string]: string }
) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: eventName, ...payload });
};

export const sendAllGoogleAnalitic = (
    isPageInExperiment = false,
    experimentalGroupId = ""
) => {
    const gtmFormSubmitPayload =
        isPageInExperiment && experimentalGroupId
            ? { experimentalGroupId }
            : undefined;

    sendGTMEvent(GTMEvents.FORM_SUBMIT_GTM_EVENT, gtmFormSubmitPayload);
};
